

<template>
  <div>
   <a-dropdown>
     <div class="header-avatar" style="cursor: pointer">
       <a-avatar class="avatar" size="small" shape="circle" :src="user.avatarPath"/>
       <span class="name">{{user.name}}</span>
     </div>
     <a-menu :class="['avatar-menu']" slot="overlay">
       <!-- <a-menu-item @click="visitShow = true">
         <a-icon type="user" />
         <span>个人中心</span>
       </a-menu-item> -->
       <!-- <a-menu-divider /> -->
       <a-menu-item @click="logout">
         <a-icon style="margin-right: 8px;" type="poweroff" />
         <span>退出登录</span>
       </a-menu-item>
     </a-menu>
   </a-dropdown>
   <a-modal
       title="个人中心"
       :visible="visitShow"
       @ok="handleOk"
       @cancel="handleCancel"
     >
       <div>
         <ul>
           <li style="margin-bottom: 10px;">
             <span>姓名：</span>
             <a-input placeholder="请输入姓名" v-model="form.name" style="width: 120px;"></a-input>
           </li>
           <li>
             <span>头像：</span>
               <a-upload :beforeUpload="beforeUpload" class="avatar-uploader" :file-list="fileList" @change="uploadChange" :multiple="false"  :data="postData" :action="qiNiuAction">
               <a-avatar
               style="width: 100px;height: 100px;"
                 v-if="!form.avatarPath"
                 size="large"
                 :style="{ cursor: 'pointer' }"
               />
               <a-avatar
               style="width: 150px;height: 150px;"
                 v-else
                 :src="form.avatarPath"
                 size="large"
                 :style="{ cursor: 'pointer' }"
               />
             </a-upload>
           </li>
         </ul>
       </div>
     </a-modal>
  </div>
 </template>
 
 <script>
 import {mapGetters} from 'vuex'
 import {logout} from '@/services/user'
 import {request} from '@/utils/request';
import { mapMutations } from 'vuex'
 
 export default {
   name: 'HeaderAvatar',
   computed: {
     ...mapGetters('account', ['user']),
   },
   data(){
     return {
       visitShow:false,
       imageUrl: '',
       loading: false,
       qiNiuAction: 'https://upload-z1.qiniup.com',
             qiNiuUploadData: {
                 key: '',
                 token: ''
             },
       getQiniuTokenIng:false,
             postData:{
                 token:''
             },
             fileList:[],
             isShowUpload:true,
             form:{
               name:"",
               avatarPath:""
             }
     }
   },
   mounted(){
     this.getQiniuToken()
     this.form.name = this.user.name
     this.form.avatarPath = this.user.avatarPath
 
   },
   methods: {
    ...mapMutations('account', ['setUser', 'setPermissions', 'setRoles']),
     logout() {
       logout()
       this.$router.push('/login')
     },
     handleOk(){
       if(!this.form.name) {
         this.$message.error('请输入姓名！')
         return false;
       }
       if(!this.form.avatarPath) {
         this.$message.error('请上传头像！')
         return false;
       }
       this.$message.loading();
       request(process.env.VUE_APP_API_BASE_URL + 'sfa/staff/'+this.user.id,'post',this.form).then((res) => {
         if(res.data.code == 200) {
           this.$message.destroy();
           this.$message.success('修改成功！')
           this.visitShow = false;
           this.getUserInfo();
          //  this.user.name = this.form.name;
          //  this.user.avatarPath = this.form.avatarPath;
         }
       })
     },
     getUserInfo(){
      request(process.env.VUE_APP_API_BASE_URL + 'srm/login', 'get').then(res => {
        this.setUser(res.data.data)
      })
     },
     handleCancel(){
       this.visitShow = false;
     },
     uploadChange(info) {
       let fileList = [...info.fileList];
       fileList = fileList.slice(-2);
       fileList = fileList.map(file => {
         if (file.response) {
           file.url = file.response.url;
         }
         return file;
       });
       this.fileList = fileList;
       this.form.avatarPath = info.file.response.url;
       console.log(this.form.avatarPath,'this.form.avatarPath')
       // console.log(e,'e')
       // this.fileList = [...e.fileList];
       // this.isShowUpload = false;
     },
     beforeUpload(file) {
             const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
             if (!isJpgOrPng) {
                 this.$message.error('You can only upload JPG file!');
             }
             const isLt30M = file.size / 1024 / 1024 < 30;
             if (!isLt30M) {
                 this.$message.error('Image must be smaller than 30MB!');
             }
             this.form.avatarPath = URL.createObjectURL(file)
             return isJpgOrPng && isLt30M;
         },
         getQiniuToken() {
                 if (!this.getQiniuTokenIng) {
                 this.getQiniuTokenIng = true
                 this.loading = true
                 this.loadingText = 'token加载中'
                 request(process.env.VUE_APP_API_BASE_URL + 'srm/qiniu/token/yjhp-hb','get').then((res) => {
                     if (res.data.code == 200) {
                     this.postData.token = res.data.data
                     // this.postData.key = 'sfa/upload' + Math.floor(Math.random() * 100) + new Date().valueOf()
                     this.getQiniuTokenIng = false
                     this.loading = false
                     } 
                 })
             }
         },
   }
 }
 </script>
 
 <style lang="less">
   .header-avatar{
     display: inline-flex;
     .avatar, .name{
       align-self: center;
     }
     .avatar{
       margin-right: 8px;
     }
     .name{
       font-weight: 500;
     }
   }
   .avatar-menu{
     width: 150px;
   }
 
   ul {
     list-style: none;
   }
 </style>
 
